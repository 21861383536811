import React from "react";

import {
  Chart as ChartJS,
  ChartData,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { MeasurementPoint } from "../data/data";

ChartJS.register(LinearScale, PointElement, LineElement);
export interface LineGraphProps {
  title: string;
  expanded: boolean;
  values: MeasurementPoint[];
  precision?: number;
}
const formatDate = (date: Date) => {
  return date.toLocaleDateString("en-GB", {
    month: "short",
    year: "2-digit",
    day: "numeric",
  });
};

export const LineGraph: React.FC<LineGraphProps> = ({
  title,
  values,
  expanded,
  precision,
}) => {
    const toFixed = precision || 0;
    values = values.filter((v) => v.value !== 0).map((v) => {return {
        ...v,
        value: parseFloat((v.value).toFixed(toFixed))  
    }});
    if (values.length < 2) {
        return <div></div>;
    }
  const minY = Math.min(...values.map((v) => v.value)) - 3;
  const maxY = Math.max(...values.map((v) => v.value)) + 3;
  const data: ChartData<"line"> = {
    labels: [...values.map((v) => formatDate(v.date))],
    datasets: [
      {
        label: title,

        data: values.map((v) => {
          return { x: v.date.getTime(), y: v.value };
        }),
        fill: false,
        borderColor: "#fdba74",
        tension: 0.4,
      },
    ],
  };
  let options = {
    grid: {
      display: false,
    },
    plugins: {
      legend: {
        display: false, // This hides all text in the legend and also the labels.
      },
    },

    responsive: true,
    scales: {
      x: {
        //display: false,

        title: {
          display: true,
          //text: 'Date'
        },
        gridLines: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 3,
        },
      },
      y: {
        min: minY,
        max: maxY,
        //display: false,
        title: {
          display: false,
          text: "",
        },
        gridLines: {
          display: false,
        },
        ticks: {
          //autoSkip: false,
          maxTicksLimit: 2,
        },
      },
    },
  };

  return <Line className="h-screen" data={data} options={options}></Line>;
};
