// Show avatars which are clickable and show the user's profile
import {
  IonCard,
  IonCardContent,
} from "@ionic/react";
import React, { useEffect } from "react";
import { ObjectModel } from "../../../common/types";
import { DashBoardComponentProps } from "../data/data";
import MeasurmentResult from "../../../components/MeasurementsResults";
import { useParams } from "react-router";

export const AvatarTab: React.FC<DashBoardComponentProps> = ({clientHolder}) => {
  const id= useParams<{id:string}>().id;
  const [visibleMeasurements, setVisibleMeasurements] = React.useState<string[]>([]);
  //const visibleMeasurements = ["neck", "chest", "waist", "buttocks"];
  const [objectModels, setObjectModels] = React.useState<ObjectModel[]>([]);
  const fetchModels = async () => {
    const client = await clientHolder.getClient();
    if (client.gender==='female') {
        setVisibleMeasurements(["neck", "chest", "waist_female", "buttocks", "leg_circ", "arm_circ"]);
    }else{
        setVisibleMeasurements(["neck", "chest", "waist", "buttocks", "leg_circ", "arm_circ"]);
    }  
    console.log("visibleMeasurements", visibleMeasurements);
    
    const models = await clientHolder.getModels();

    setObjectModels(models);
    return
  };
  useEffect(() => {
    fetchModels();
  }, [id]);

  return (
    <IonCard className="p-0 m-0">
     
      <IonCardContent className="p-0">
{         objectModels.length && 
                 <MeasurmentResult visibleMeasurements={visibleMeasurements} objectModels={objectModels} setObjectModel={()=>{}} measurementList={objectModels.map(o=>o.measurements)}/>
}      </IonCardContent>
    </IonCard>
  );
};
