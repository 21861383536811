import { PluginListenerHandle } from '@capacitor/core';
import { Motion, RotationRate } from '@capacitor/motion';
import { IonIcon, useIonViewDidEnter } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import { Context } from '../MyContext';
import { caretDownOutline, caretUpOutline } from 'ionicons/icons';

const DeviceOrientation: React.FC = () => {
	const [showPermButton, setShowPermButton] = useState(false);
    const [showArrows, setShowArrows] = useState(true);
	const [showButtons, setShowButtons] = useState(true);
	const [orientation, setLocalOrientation] = useState<RotationRate>({ alpha: 0, beta: 0, gamma: 0 });
	const { setOrientation: setGlobalOrientation } = useContext(Context);
    const tolerance = 5;

	let orientationHandler: PluginListenerHandle;

	useIonViewDidEnter(() => {
		if (Capacitor.isPluginAvailable('Motion')) {
			if (
				DeviceOrientationEvent !== undefined &&
				typeof (DeviceOrientationEvent as any).requestPermission === 'function'
			) {
				setShowPermButton(true);
			}
		} else {
			setShowButtons(false);
		}
	});

	const listenOrientation = async () => {
		// Request permission for iOS 13+ devices
		if (
			DeviceOrientationEvent !== undefined &&
			typeof (DeviceOrientationEvent as any).requestPermission === 'function'
		) {
			const permission = await (DeviceOrientationEvent as any).requestPermission();
			if (permission !== "granted") {
				console.log("Permission denied")
				return;
			}
			else {
				console.log("Permission granted")
			}
		}

		orientationHandler = await Motion.addListener('orientation', event => {
			setLocalOrientation(event);
			setGlobalOrientation(event);
            setShowArrows(Math.abs(90 - event.beta) > tolerance);
		});
	};

	useEffect(() => {
		listenOrientation()
	}, [])

	return (
        <div className='motionInfo'>
                <div 
                className='vertical-bar'
                style={{
                    backgroundColor: Math.abs(90 - orientation.beta) < 5 ? '#4caf50' : '#ff0000'
                }}
            />
		{showArrows && (
            orientation.beta > 90 ? (
                <IonIcon icon={caretDownOutline} className='icon-size down' />
            ) : (
                <IonIcon icon={caretUpOutline} className='icon-size up' />
            )
        )}
        </div>
	);
};

export default DeviceOrientation;