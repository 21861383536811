// Dashboard main page
import {
  IonHeader,
  IonIcon,
  IonPage,
  IonTab,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  bodyOutline,
  analyticsOutline,
  fitnessOutline,
  home,
} from "ionicons/icons";
import { OverallFitness } from "./components/OverallFitnessTab";
import { Trends } from "./components/TrendsTab";
import { AvatarTab } from "./components/AvatarTab";
import HomeTab from "./components/HomeTab";
import { useEffect, useState, } from "react";
import { useParams, useLocation } from "react-router";
import { ClientDataHolder } from "./data/data";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthProvider } from "../utils/http-service";

const tabs = (client:ClientDataHolder, first?:string) => {
    return [
   (<IonTab key="home" tab="home">
        <div id="home-page">
        <IonToolbar>
              <IonTitle>Home</IonTitle>
            </IonToolbar>
         {client &&  <HomeTab clientHolder={client} ></HomeTab>}
        </div>
      </IonTab>),
      (<IonTab key="avatar" tab="avatar">
        <div id="avatar-page">
          <IonHeader>
            <IonToolbar>
              <IonTitle>Avatar</IonTitle>
            </IonToolbar>
          </IonHeader>
          <AvatarTab clientHolder={client}></AvatarTab>
        </div>
      </IonTab>)].sort((a,b)=>a.props.tab==first?-1:0);}
const DashboardPage = () => {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const params = useParams<{ id: string }>();
    //const searchParams = new URLSearchParams(window.location.search);
    let searchParams = new URLSearchParams(useLocation().search);
    //let searchParams = new URLSearchParams(useLocation().search);
    const showTab = searchParams.get("show")||undefined;
    const code = searchParams.get("code")||undefined;
    const auth = new AuthProvider(getAccessTokenSilently, code);
    const clientId = params.id;
    const [client, setClient] = useState<ClientDataHolder>(new ClientDataHolder(clientId, auth));
    useEffect(() => {
        client.load()
    }, [clientId]);
    
  return (
    <IonPage className="mt-4">
    <IonTabs>
        {client && tabs(client, showTab)}
    
      <IonTab tab="trends" className="overflow-y-scroll">
        <div id="trends">
          <IonHeader>
            <IonToolbar>
              <IonTitle>Trends</IonTitle>
            </IonToolbar>
          </IonHeader>
{        client &&  <Trends clientHolder = {client}></Trends>
}        </div>
      </IonTab>
      <IonTab tab="overall" className="overflow-y-scroll">
        <div id="overall-page">
          <IonHeader>
            <IonToolbar>
              <IonTitle>Overall fitness</IonTitle>
            </IonToolbar>
          </IonHeader>
          {client && <OverallFitness clientHolder={client}></OverallFitness>}
        </div>
      </IonTab>

      <IonTabBar slot="bottom">
      <IonTabButton tab="home">
          <IonIcon icon={home} />
          Home
        </IonTabButton>
        <IonTabButton tab="avatar" >
          <IonIcon icon={bodyOutline} />
          Avatar
        </IonTabButton>
        <IonTabButton tab="trends">
          <IonIcon icon={analyticsOutline} />
          Trends
        </IonTabButton>
        <IonTabButton tab="overall">
          <IonIcon icon={fitnessOutline} />
          Fitness
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
    </IonPage>
  );
};
export default DashboardPage;
