import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import { MeasurementPoint } from "../data/data";
import { caretDown, caretUp } from "ionicons/icons";

export const showDifference = (data: MeasurementPoint[], name: string) => {
  const showName = name
    .split("_")
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))[0];
  if (data.length < 1) {
    return (
      <IonItem>
        <IonLabel>{showName}</IonLabel>
      </IonItem>
    );
  }
  const diff = data[data.length - 1].value - data[0].value;
  const caretColor = diff < 0 ? "text-lime-500" : "text-orange-500";
  let unit = "cm";
  switch (name) {
    case "weight":
      unit = "kg";
      break;
    case "Body Fat":
      unit = "%";
      break;
    case "waist":
    case "hips":
    case "neck":
      unit = "cm";
      break;
  }

  return (
    <IonItem>
      <IonLabel>{showName} </IonLabel>
      {data.length === 1 && (
        <IonLabel slot="end">
          {data[0].value.toFixed(0)} {unit}
        </IonLabel>
      )}
      {data.length > 1 && (
        <>
          {" "}
          {diff.toFixed(0)} {unit}{" "}
          <IonIcon
            icon={diff < 0 ? caretDown : caretUp}
            size="large"
            className={caretColor}
          ></IonIcon>
        </>
      )}
    </IonItem>
  );
};
