import { getBodyFatTable } from "../data/bodyfatTable";
import { IonBadge, IonItem, IonLabel, IonList } from "@ionic/react";

interface Props {
  bodyFat: number;
  age: number;
  gender: "male" | "female";
}
export const BodyFatCategory: React.FC<Props> = ({ bodyFat, age, gender }) => {
  const categories = getBodyFatTable(gender, age);
  const bf = Math.round(bodyFat);
  const category = categories.find(
    (cat) => bf >= cat.min && bf <= cat.max
  );
  return (
    <IonList>
      <IonItem>
        <IonLabel slot="start">Body fat: </IonLabel>

        <IonBadge slot="end" className={category?.color}>
            {bodyFat.toFixed(0)}%
        </IonBadge>
      </IonItem>
      <IonItem>
        <IonLabel slot="start">Category: </IonLabel>

        <IonBadge slot="end" className={category?.color}>
          {category?.cat}{" "}
        </IonBadge>
      </IonItem>
    </IonList>
  );
};
