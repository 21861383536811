// Show lean mass, body fat mass, and body fat percentage in a graph

import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale } from "chart.js";
import { IonCard, IonCardContent } from "@ionic/react";
import { BodyFatIndicator } from "./BodyFatIndicator";
import { useEffect, useState } from "react";
import { LineGraph } from "./LineGraph";
import { showDifference } from "./GraphHeader";
import { ClientData } from "../../utils/interfaces";
import { Loading } from "./Loading";
import { DashBoardComponentProps, MeasurementPoint } from "../data/data";
import { BodyFatCategory } from "./BodyFatCategory";
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale);

const getBodyFat = (waist: number, neck: number, buttocks:number ,height: number, gender:'male'|'female') => {
    // normalize from cm to inches
    height = height * 0.393701;
    neck = neck * 0.393701;
    waist = waist * 0.393701;
    buttocks = buttocks * 0.393701;
    // use US navy method to calculate body fat percentage
    if (gender === 'male') {
        return 86.010 * Math.log10(waist - neck) - 70.041 * Math.log10(height) + 36.76;
    }else{
        //if (IS_MOCK) {
        waist -=5; // TODO: retrain model
        //} 
        return 163.205 * Math.log10(waist + buttocks - neck) - 97.684 * Math.log10(height) - 78.387;
    }
}

export const OverallFitness: React.FC<DashBoardComponentProps> = ({clientHolder}) => {
    const [clientData, setClientData] = useState<ClientData>();
    const [weight, setWeight] = useState<MeasurementPoint[]>([]);

    const [bodyFat,setBodyFat] = useState<number[]>([]);
    const [leanMass, setLeanMass] = useState<MeasurementPoint[]>([]);
    const [bodyFatMass, setBodyFatMass] = useState<MeasurementPoint[]>([]);
    useEffect(() => {
        const fetchData = async () => {
            const client = await clientHolder.getClient()
            setClientData(client);
            const height = client.height;
            const gender = client.gender;
            const data =  await clientHolder.getMeasurements();

            const waist = data.map(m => {
                if (gender === 'female'){
                    if (m.measurements.waist_female){
                        return m.measurements.waist_female;
                    }else{
                        return m.measurements.waist-5; // TODO remove
                    }
                }
                return m.measurements.waist;
            } );
            const neck = data.map(m => m.measurements.neck );
        
            //const height = 
            const buttocks = data.map(m => m.measurements.buttocks );

            const bodyFatC = waist.map((w,i) => getBodyFat(w, neck[i], buttocks[i], height, gender));
            setBodyFat(bodyFatC);
            const weightC = data.map(m => {return {id: m.ID || ' ', date: new Date(m.createdAt), value: m.measurements.weight || 0}});
            setWeight(weightC);

        };
        fetchData();
    }, []);
    if (bodyFat.length === 0) {
        return <div>Loading...</div>;
    }
    const data = {
        labels: ["Lean Mass", "Body Fat Mass"],
        datasets: [
            {
                label: "Kg",
                data: [leanMass[leanMass.length-1], bodyFatMass[bodyFatMass.length-1]],
                backgroundColor: ["#22c55e", "#36A2EB"],
                hoverOffset: 4,
            },
        ],
    };

   




    return (
        clientData? <>
            <IonCard>
                <IonCardContent>
                    <BodyFatCategory bodyFat={bodyFat[bodyFat.length-1]} gender={clientData.gender} age={clientData.age}></BodyFatCategory>
                    <BodyFatIndicator bodyFat={bodyFat[bodyFat.length-1]} gender={clientData.gender} age={clientData.age}></BodyFatIndicator>
                </IonCardContent>

            </IonCard>
            <IonCard >
                <IonCardContent >
                    {showDifference(bodyFat.map((b,i)=>{return {value: b, date: new Date(), id:i+''}}), "Body Fat")}
                    <LineGraph title="Body Fat" expanded={false} values={bodyFat.map((m,i) => { return { id: i+"" , date: new Date(), value: m } })} />
                    {showDifference(weight, "weight")}
                    <LineGraph title="Weight" expanded={false} values={weight} />

                </IonCardContent>
            </IonCard>
        </>:<Loading></Loading>
    );
}
