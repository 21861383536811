import { PIPEDB_URL } from "../../common/pipedb-url";
import { ClientData, MeasureData, ObjectModelData, ShareLinkData, TaskData } from "./interfaces";
import { mockClients, mockMeasurements, mockModels } from "./mock";


export const IS_MOCK = false;
export class AuthProvider {
    getAccessTokenSilently: () => Promise<string>;
    code?: string;

    constructor(getAccessTokenSilently: () => Promise<string>, code?: string) {
        this.code = code;
        this.getAccessTokenSilently = getAccessTokenSilently;
    }
    getAuthHeader = async () => {
        let headers = {
            'Content-Type': 'application/json',
        } as { [key: string]: string };
        if (this.code) {
            headers = {
                ...headers,
                Authorization: `Code ${this.code}`
            };
        } else {
            const token = await this.getAccessTokenSilently();
            if (token) {
                headers = {
                    ...headers,
                    Authorization: `Bearer ${token}`
                };
            }
        }
        return headers;
    }

}

export const post = async (url: string, data: any, auth: AuthProvider) => {

    return fetch(`${PIPEDB_URL}/${url}`, {
        method: 'POST',
        headers: await auth.getAuthHeader(),
        body: JSON.stringify(data),
    });
}
export const get = async (url: string, auth: AuthProvider) => {
    const headers = await auth.getAuthHeader();
    return fetch(`${PIPEDB_URL}/${url}`, { headers: headers });
}

export const del = async (url: string) => {
    return fetch(`${PIPEDB_URL}/${url}`, {
        method: 'DELETE',
    });
}
export const getClients = async (auth: AuthProvider): Promise<ClientData[]> => {
    if (IS_MOCK) {
        return mockClients();
    }
    const res = await get('client', auth);
    return res.json();
}
export const addResource = async (resourceName: string,taskID:string,  data: any, auth: AuthProvider, raw=false) => {
    const res = await post(`task/${taskID}/${resourceName}`, data, auth);
    
    if (raw) {
        return res;
    }
    if (res.status !== 200) {
        throw new Error(JSON.stringify(await res.json()));
    }
    return res.json();
}
export const getResource = async (resourceName: string,taskID:string, auth: AuthProvider, raw=false) => {
    const res = await get(`task/${taskID}/${resourceName}`, auth);
    if (raw) {
        return res;
    }
    if (res.status !== 200) {
        throw new Error(JSON.stringify(await res.json()));
    }
    return res.json();
}

export const addClient = async (client: ClientData, auth: AuthProvider) => {
    const res = await post('client', client, auth);
    if (res.status !== 200) {
        throw new Error(JSON.stringify(await res.json()));
    }
    return res.json();
}
export const getClient = async (id: string, auth: AuthProvider): Promise<ClientData> => {
    if (IS_MOCK) {
        return (await mockClients()).find(c => c.ID === id) as ClientData;
    }
    const res = await get(`client/${id}`, auth);
    return res.json();
}
export const initTask = async (client: ClientData, auth: AuthProvider): Promise<TaskData> => {
    const task = (await (await post('task', { client: client.ID }, auth)).json()) as TaskData;
    return task;
}

export const startTask = async (client: ClientData, weight: number | undefined, id: string, auth: AuthProvider) => {
    await post(`task/${id}/front_proj`, {
        scale: [2.0, 1.5],
        center: [0, 0]
    }, auth);
    await post(`task/${id}/right_proj`, {
        scale: [2.0, 1.5],
        center: [0, 0]
    }, auth);
    await post(`task/${id}/height_input`, {
        value: Number(client.height / 100)
    }, auth);
    await post(`task/${id}/gender`, {
        value: client.gender
    }, auth);
    if (weight) {
        await post(`task/${id}/weight`, {
            value: weight
        }, auth);
    }
    return id;
}
export const getLink = async (id: string, auth: AuthProvider): Promise<ShareLinkData> => {
    const res = await get(`link/${id}`, auth);
    return res.json();
}
export const saveLink = async (id: string, link: ShareLinkData, auth: AuthProvider) => {
    const res = await post(`link/${id}`, { link }, auth);
    if (res.status !== 200) {
        throw new Error(JSON.stringify(await res.json()));
    }
    return res.json();
}
export const saveTask = async (id: string, auth: AuthProvider): Promise<TaskData> => {
    const res = await post(`save_task/${id}`, {}, auth);
    if (res.status !== 200) {
        throw new Error(JSON.stringify(await res.json()));
    }
    return res.json();
}
export const getMeasurements = async (clientId: string, auth: AuthProvider): Promise<MeasureData[]> => {
    if (IS_MOCK) {
        return mockMeasurements(clientId);
    }
    const res = await get(`measurements/${clientId}/`, auth);
    return res.json();
}

export const getModels = async (clientId: string, auth: AuthProvider): Promise<ObjectModelData[]> => {
    if (IS_MOCK) {
        return mockModels(clientId);
    }
    const res = await get(`models/${clientId}/`, auth);
    return res.json();
}
