export interface BodyFatCategory {
    cat: string;
    min: number;
    max: number;
    color?: string;
}


export const getBodyFatTable = (gender: 'male' | 'female', age: number): BodyFatCategory[] => {

    const categories = [
        {
            ageRange: [18, 39],
            gender: 'male',
            categories: [
                { cat: "Underfat", min: 0, max: 8 },
                { cat: "Healthy", min: 9, max: 18 },
                { cat: "Overfat", min: 19, max: 24 },
                { cat: "Obese", min: 25, max: 45 },
            ]
        },
        {
            ageRange: [40, 59],
            gender: 'male',
            categories: [
                { cat: "Underfat", min: 0, max: 11 },
                { cat: "Healthy", min: 12, max: 20 },
                { cat: "Overfat", min: 21, max: 27 },
                { cat: "Obese", min: 28, max: 45 },
            ]
        },
        {
            ageRange: [60, 79],
            gender: 'male',
            categories: [
                { cat: "Underfat", min: 0, max: 13 },
                { cat: "Healthy", min: 14, max: 22 },
                { cat: "Overfat", min: 23, max: 30 },
                { cat: "Obese", min: 31, max: 45 },
            ]
        },
        {
            ageRange: [18, 39],
            gender: 'female',
            categories: [
                { cat: "Underfat", min: 0, max: 21 },
                { cat: "Healthy", min: 22, max: 33 },
                { cat: "Overfat", min: 34, max: 39 },
                { cat: "Obese", min: 40, max: 45 },
            ]
        },
        {
            ageRange: [40, 59],
            gender: 'female',
            categories: [
                { cat: "Underfat", min: 0, max: 23 },
                { cat: "Healthy", min: 24, max: 35 },
                { cat: "Overfat", min: 36, max: 40 },
                { cat: "Obese", min: 41, max: 45 },
            ]
        },
        {
            ageRange: [60, 79],
            gender: 'female',
            categories: [
                { cat: "Underfat", min: 0, max: 24 },
                { cat: "Healthy", min: 25, max: 38 },
                { cat: "Overfat", min: 39, max: 44 },
                { cat: "Obese", min: 45, max: 45 },
            ]




        }
    ]
    const underColor = "bg-blue-300";
    const healthyColor = "bg-lime-300";
    const overColor = "bg-yellow-300";
    const obeseColor = "bg-red-300";
    const category:BodyFatCategory[] = categories.find((cat) => {
        return cat.gender === gender && cat.ageRange[0] <= age && cat.ageRange[1] >=age} )?.categories as BodyFatCategory[];
    if (!category) {
        return [];
    }
    category[0].color = underColor;
    category[1].color = healthyColor;
    category[2].color = overColor;
    category[3].color = obeseColor;
    return category;

}