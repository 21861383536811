import React from "react";
//import { IonSlides, IonSlide, IonContent, IonButton,  IonIcon, } from "@ionic/react";
import {  arrowBackOutline } from "ionicons/icons";
import "../theme/InstructionsSlides.css";
import "../theme/Utilities.css";


import FrontPoseInstructions from "../assets/Instructions/FrontPoseInstructions.png";
import SidePoseInstructions from "../assets/Instructions/SidePoseInstructions.png";
import { IonContent } from "@ionic/react";
// Optional parameters to pass to the swiper instance.
// See https://swiperjs.com/swiper-api for valid options.
const slideOpts = {
  initialSlide: 1,
  speed: 400,
};

export const InstructionSlides: React.FC = () => (
  <IonContent>
    <h1>1</h1>
    {/* <IonSlides pager={true} options={1} className="InstructionSlides">
      <IonSlide className="FrontPoseSlide">
        <IonContent>
          <div className="image">
            <img src={FrontPoseInstructions} alt="" />
          </div>
        
                <div className="buttonsUnderSlides">
                  <IonButton color="warning" shape="round" fill="clear">
                    <IonIcon
                      className="rightArrow"
                      slot="end"
                icon={arrowBackOutline}
             
                    />
                    Swipe right
                  </IonButton>
                </div>
            
        </IonContent>
      </IonSlide>

      <IonSlide className="SidePoseSlide">
        <IonContent>
          <div className="image">
            <img src={SidePoseInstructions} alt="" />
          </div>

          <div className="buttonsUnderSlides">
            <IonButton color="warning" shape="round" fill="clear">
              <IonIcon
                className="leftArrow"
                slot="start"
                icon={arrowBackOutline}
              />
              Swipe left
            </IonButton>
          </div>
        </IonContent>
      </IonSlide>
    </IonSlides> */}
  </IonContent>
);
