import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSearchbar,
} from "@ionic/react";
import React, { useEffect } from "react";
import Toolbar from "../../components/Toolbar";
import { addOutline } from "ionicons/icons";
import { AuthProvider, getClients } from "../utils/http-service";
import { ClientData } from "../utils/interfaces";
import { ProfileImage } from "../dashboard/components/ProfileImage";
import { useAuth0, User } from "@auth0/auth0-react";
import LoginButton from "../../components/LoginButton";
const UserItem = (client: ClientData) => {
  return (
    <IonItem key={client.ID} routerLink={`/dashboard/${client.ID}`}>
      <ProfileImage
        profileImage={client.profileImage}
        slot={"start"}
      ></ProfileImage>
      <IonLabel>
        {client.firstName} {client.lastName}
      </IonLabel>
    </IonItem>
  );
};

export const ClientListPage: React.FC = () => {
  const [search, setSearch] = React.useState("");
  const [results, setResults] = React.useState<ClientData[]>([]);
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
  const auth = new AuthProvider(getAccessTokenSilently);

  useEffect(() => {
    getClients(auth).then((data) => {
      setResults(data);
    });
  }, []);
  const handleInput = (ev: Event) => {
    let query = "";
    const target = ev.target as HTMLIonSearchbarElement;
    if (target) query = target.value!.toLowerCase();
    setSearch(query);
    setResults(
      results.filter((client: ClientData) => {
        return (
          client.firstName.toLowerCase().includes(query) ||
          client.lastName.toLowerCase().includes(query)
        );
      })
    );
  };
  if (isLoading) {
    return (
      <IonPage className="mt-16">
     
      </IonPage>
    );
  }
  if (!isAuthenticated) {
    return (
      <IonPage className="mt-16">
       <IonContent>
          <IonCard>
            <IonCardHeader>
              <IonLabel>Please log in</IonLabel>
            </IonCardHeader>
            <IonCardContent>
              <LoginButton></LoginButton>
            </IonCardContent>
          </IonCard>
        </IonContent>
      </IonPage>
    );
  }

  return (
    <IonPage className="mt-16">

      <IonContent>
        <IonCard className="h-[75%]">
          <IonCardHeader>
            <IonLabel className="text-lg">Clients</IonLabel>
          </IonCardHeader>
          <IonSearchbar
            debounce={100}
            animated={true}
            placeholder="Name"
            onIonInput={(e) => handleInput(e)}
          ></IonSearchbar>

          <IonList>{results.map((client) => UserItem(client))}</IonList>
        </IonCard>
        <IonCard>
          <div className="flex p-4">
            <IonButton fill="outline" routerLink="/addclient">
              New client <IonIcon slot="right" icon={addOutline}></IonIcon>
            </IonButton>
          </div>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};
