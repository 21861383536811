import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import './theme/tailwind.css';

/* Basic CSS for apps built with Ionic */
//import '@ionic/react/css/normalize.css';
//import '@ionic/react/css/structure.css';
//import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
//import '@ionic/react/css/padding.css';
//import '@ionic/react/css/float-elements.css';
//import '@ionic/react/css/text-alignment.css';
//import '@ionic/react/css/text-transformation.css';
//import '@ionic/react/css/flex-utils.css';
//import '@ionic/react/css/display.css';

/* Theme variables */
//import './theme/variables.css';
import FrontInstructionsPage from './pages/FrontInstructionsPage';
import MyContextProvider from './MyContext';
import SideInstructionsPage from './pages/SideInstructionsPage';
import ResultPage from './pages/ResultPage';
import MeasurementsPage from './pages/MeasurementsPage';
import MeasurementsResultsPage from './pages/MeasurementsResultsPage';
import InstructionsOnSwipe from './pages/InstructionsOnSwipe';
import PhotoCapture from './pages/PhotoCapture';
import React from 'react';
import {PingAPI} from './hooks/PingAPI';
import DashboardPage from './pt/dashboard/DashBoardPage';
import { ClientListPage } from './pt/clientlist/page';
import { AddClient } from './pt/clientlist/AddClient';
import Toolbar from './components/Toolbar';
import CamTest from './pages/WebcamTest';
setupIonicReact();

const App: React.FC = () => {


    //PingAPI();

    return (<IonApp>
        <MyContextProvider>
            <IonReactRouter>
            <Toolbar />

                <IonRouterOutlet>
                    <Route exact path="/home">
                        <Home />
                    </Route>

                    {<Route exact path="/cam" component={CamTest} /> }
                    <Route
                        exact
                        path="/task/:id/results"
                        component={MeasurementsResultsPage}
                    />
                    <Route exact path="/measurements" component={MeasurementsPage} />
                    <Route
                        exact
                        path="/task/:id/:gender/front_img"
                        component={FrontInstructionsPage}
                    />
                    <Route
                        exact
                        path="/task/:id/:gender/right_img"
                        component={SideInstructionsPage}
                    />
                    <Route exact path="/task/:id/result" component={ResultPage} />
                    <Route
                        exact
                        path="/InstructionsOnSwipe"
                        component={InstructionsOnSwipe}
                    />
                    <Route
                        exact
                        path="/photo/:id/:gender/:pose"
                        component={PhotoCapture}
                    />
                    <Route
                        exact
                        path="/dashboard/:id"
                        component={DashboardPage} 
                    />
                     <Route
                        exact
                        path="/clients"
                        component={ClientListPage} 
                    />
                    <Route
                        exact
                        path="/addclient"
                        component={AddClient}
                    />
                    <Route exact path="/">
                        <Redirect to="/clients" />
                    </Route>

                </IonRouterOutlet>
            </IonReactRouter>
        </MyContextProvider>
    </IonApp>);
}


export default App;
