import { IonContent, IonPage } from "@ionic/react";
import { useState } from "react";
//import "../theme/PhotoPreview.css";
//import "../theme/Utilities.css";
import  {CameraOverlay}  from "../components/CameraOverlay";
const CamTest: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <IonPage className="z-50">
      <IonContent fullscreen>
        <CameraOverlay
            setUploaded={() => {}}
          id={"123"}
          pose={"f"}
          gender={"male"}
          uploadPhoto={(data:string) => {
            // read jpeg data
            const b64 = data.split(",")[1];

            console.log(b64)
            // check size of the image
            const image = new Image();
            image.src = data;
            image.onload = function() {
              const jaja =  window.confirm("Image size:  w: "+  image.width + ", h: " + image.height);
              if (jaja) {
                // download the image to user device
                const a = document.createElement("a");
                a.href = data;
                a.download = "image.jpg";
                a.click();
                }
                
            };
        }}
        />
      </IonContent>
    </IonPage>
  );
};

export default CamTest;
