import {
  IonButton,
  IonCard,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonSpinner,
  IonText,
  useIonRouter,
} from "@ionic/react";
import { useEffect, useState } from "react";
import "../theme/Results.css";

import "../theme/Utilities.css";
import { useParams } from "react-router";
import { MeasurementResource, ObjectModel } from "../common/types";
import MeasurmentResult from "../components/MeasurementsResults";
import { AuthProvider, getResource, saveTask } from "../pt/utils/http-service";
import { useAuth0 } from "@auth0/auth0-react";
import { saveOutline } from "ionicons/icons";
const ResultPage: React.FC = () => {
  const auth = new AuthProvider(useAuth0().getAccessTokenSilently);
  const router = useIonRouter();
  const { id } = useParams<{ id: string }>();
  const [isFetched, setIsFetched] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [visibleMeasurements, setVisibleMeasurements] = useState<string[]>(['neck', 'chest', 'buttocks', 'leg_circ', 'arm_circ']);
  const [objectModel, setObjectModel] = useState<ObjectModel>({
    id: "",
    createdAt: "",
    obj: "",
    visibleEffects: [0],
    showMeasurement: "",
    measurements: {
      id: "",
      createdAt: "",
      height: 0,
      head: 0,
      neck: 0,
      chest: 0,
      waist: 0,
      buttocks: 0,
      leg_inner: 0,
      arm_outer: 0,
    },
  });

  const [measurements, setMeasurements] = useState<MeasurementResource>({
    id: "",
    createdAt: "",
    height: 0,
    head: 0,
    neck: 0,
    chest: 0,
    waist: 0,
    buttocks: 0,
    leg_inner: 0,
    arm_outer: 0,
  });

  const fetchModel = async () => {
    //await get(`${PIPEDB_URL}/task/${id}/model`, auth)
    await getResource("model", id, auth, true)
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        

        return res.json();
      })
      .then((data) => {
        console.log(data);
        if (data['gender'] ==='female' || data['gender'] && data['gender']['value'] === 'female' ){
            console.log("add waist_female")
            setVisibleMeasurements([...visibleMeasurements,'waist_female'])
        }else{
            setVisibleMeasurements([...visibleMeasurements,'waist'])
            console.log("add waist")
        }
        setObjectModel(data);
        setIsFetched(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchMeasurements = async () => {
    const heightInput = await getResource("height_input", id, auth, true)
   // const heightInput = await get(`${PIPEDB_URL}/task/${id}/height_input`, auth)
      .then((res) => res.json())
      .then((data) => data.value);

   /*  await fetch(`${PIPEDB_URL}/task/${id}/measurements`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }

        return res.json();
      }) */
    await getResource("measurements", id, auth)

      .then((data) => {
        const scale = (heightInput * 1000) / data.height;
        console.log("SCALE!!!", scale);
        [
          "height",
          "head",
          "neck",
          "chest",
          "waist",
          "buttocks",
          "leg_inner",
          "arm_outer",
          "arm_upper",
          "arm_circ",
          "leg_circ",
          "waist_female",
        ].forEach((key) => {
          data[key] = data[key] * scale;
        });
        setMeasurements(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveModel = async () => {
    try {
      const task = await saveTask(id, auth);
      setIsSaved(true);
      router.push(`/dashboard/${task.client}/?show=avatar`);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // if new taskId, setIsfetched to false
    setIsFetched(false);
  }, [id]);

  useEffect(() => {
    if (!isFetched) {
      const interval = setInterval(() => {
        fetchModel();
        fetchMeasurements();
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isFetched]);

  return (
    <IonPage className="mt-8">
      {!isFetched ? (
        <IonContent>
          <div className="loadingIconText">
            <div className="loadingIcon">
              <IonSpinner name="lines-sharp"></IonSpinner>
            </div>
            <br></br>
            <br></br>
            <IonText className="loadingText">
              <h1>Please wait...</h1>
            </IonText>
          </div>
        </IonContent>
      ) : (
        <IonContent>
          <IonGrid className="resultContent">
            <MeasurmentResult
              objectModels={[objectModel]}
              setObjectModel={setObjectModel}
              measurementList={[measurements]}
              visibleMeasurements={visibleMeasurements}
            />
            <IonCard>
              <IonButton
                disabled={isSaved}
                fill="outline"
                color="success"
                onClick={() => {
                  saveModel();
                }}
              >
                Save
                <IonIcon icon={saveOutline}></IonIcon>
              </IonButton>
            </IonCard>
          </IonGrid>
        </IonContent>
      )}
    </IonPage>
  );
};

export default ResultPage;
