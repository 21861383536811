import React from "react";
import { getBodyFatTable } from "../data/bodyfatTable";

interface Props {
  bodyFat: number;
  gender: "male" | "female";
  age: number;
}

const bodyFatToPercentage = (bodyFat: number, maxVal: number) => {
  return Math.ceil((bodyFat / maxVal) * 100);
};


export const BodyFatIndicator: React.FC<Props> = ({ bodyFat, gender, age }) => {
 
  const underColor = "bg-blue-300";
  const healthyColor = "bg-lime-300";
  const overColor = "bg-yellow-300";
  const obeseColor = "bg-red-300";
  

  // w-[0%] w-[1%] w-[2%] w-[3%] w-[4%] w-[5%] w-[6%] w-[7%] w-[8%] w-[9%] w-[10%] w-[11%] w-[12%] w-[13%] w-[14%] w-[15%] w-[16%] w-[17%] w-[18%] w-[19%] w-[20%] w-[21%] w-[22%] w-[23%] w-[24%] w-[25%] w-[26%] w-[27%] w-[28%] w-[29%] w-[30%] w-[31%] w-[32%] w-[33%] w-[34%] w-[35%] w-[36%] w-[37%] w-[38%] w-[39%] w-[40%] w-[41%] w-[42%] w-[43%] w-[44%] w-[45%] w-[46%] w-[47%] w-[48%] w-[49%]
  // w-[9%] w-[10%] w-[11%] w-[12%] w-[13%] w-[14%] w-[15%] w-[16%] w-[17%] w-[18%] w-[19%] w-[20%] w-[21%] w-[22%] w-[23%] w-[24%] w-[25%] w-[26%] w-[27%] w-[28%] w-[29%] w-[30%] w-[31%] w-[32%] w-[33%] w-[34%] w-[35%] w-[36%] w-[37%] w-[38%] w-[39%] w-[40%] w-[41%] w-[42%] w-[43%] w-[44%] w-[45%] w-[46%] w-[47%] w-[48%] w-[49%] w-[50%] w-[51%] w-[52%] w-[53%] w-[54%] w-[55%] w-[56%] w-[57%] w-[58%] w-[59%] w-[60%] w-[61%] w-[62%] w-[63%] w-[64%] w-[65%] w-[66%] w-[67%] w-[68%] w-[69%] w-[70%] w-[71%] w-[72%] w-[73%] w-[74%] w-[75%] w-[76%] w-[77%] w-[78%] w-[79%] w-[80%] w-[81%] w-[82%] w-[83%] w-[84%] w-[85%] w-[86%] w-[87%] w-[88%] w-[89%] w-[90%] w-[91%] w-[92%] w-[93%] w-[94%] w-[95%] w-[96%] w-[97%] w-[98%] w-[99%] w-[100%]
  const maxValue = 45;
  const markerValues = [0, 10, 20, 30, 40];
  const categories = getBodyFatTable(gender, age).map((cat) => {
    return {
      cat: cat.cat,
      width: bodyFatToPercentage(cat.max + 1 - cat.min, maxValue),
      color: "bg-",
    };
  });
  categories[0].color = underColor;
  categories[1].color = healthyColor;
  categories[2].color = overColor;
  categories[3].color = obeseColor;
  const fillColor = "fill-slate-500";
  const markerPositionClass = `h-12 flex flex-col items-center absolute top-0 -mr-4`;
  return (
    <div className="relative rounded-xl overflow-auto">

      <div className=" relative pt-10 h-10 rounded-lg flex">
      <div className="h-full w-full absolute flex">
        {categories.map((cat, index) => {
          // round left and right corners
          const rounded =
            index === 0
              ? "rounded-l-lg"
              : index === categories.length - 1
              ? "rounded-r-lg"
              : "";
          const className = `${rounded} flex text-center h-8 w-[${
            Math.round(cat.width) + ""
          }%] ${cat.color}`;
          return <div key={index} className={className}></div>;
        })}

      </div>
      <div className="z-10 absolute w-full h-full">{markerValues.map((v,i)=>{
        return <div key={i} className="absolute top-0 left-0 w-0.5 h-8 bg-gray-900/30 dark:bg-white/30" style={{left:`${bodyFatToPercentage(v,maxValue)}%`}}> <div className="-my-8">{v}%</div></div>
      })}</div>
      <div className="z-10 absolute w-full h-full">{
        <div className="absolute top-0 -mx-1 -my-1 w-2 h-10 border-solid border-2 border-green-500" style={{left:`${bodyFatToPercentage(bodyFat,maxValue)}%`}}> 
        </div>
        }</div>
      </div>
      <div className="h-10 flex mt-10">
        {categories.map((cat, index) => {
          return (
            <div
              key={index + cat.cat}
              className={` w-[${Math.round(
                cat.width
              )}%] text-center align-center `}
            >
              {cat.cat}
            </div>
          );
        })}
      </div>
    </div>
  );
};
