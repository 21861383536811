import {  ObjectModel } from "../../../common/types";
import { AuthProvider, getClient, getMeasurements, getModels } from "../../utils/http-service";
import { ClientData, MeasureData, ObjectModelData } from "../../utils/interfaces";
export interface MeasurementPoint {
    id: string;
    date: Date;
    value: number;
};

export interface DashBoardComponentProps {
    clientHolder: ClientDataHolder;
}

export class ClientDataHolder {
    ID: string;
    auth: AuthProvider;
    data?: ClientData;
    loadedMeasurements: boolean;
    loadedModels: boolean;
    clientLoaded: boolean;
    measurements: MeasureData[];
    objectModels: ObjectModelData[];
    constructor(id: string, auth:AuthProvider) {
        this.ID = id;
        this.auth = auth;
        this.loadedMeasurements = false;
        this.loadedModels = false;
        this.clientLoaded = false;
        this.measurements = [];
        this.objectModels = [];
    }
    
    getClient = async (force?:boolean): Promise<ClientData> => {
        if (force) {
            return this.loadClientData();
        }
        return this.data || this.loadClientData();
    }
    getMeasurements = async (unit:'cm'|'mm'='cm'): Promise<MeasureData[]> => {
        let measurements =  this.loadedMeasurements? this.measurements : await this.loadMeasurements();
        if (unit === 'mm') {
            measurements = measurements.map(m => {
                const measure = m.measurements;
                return {
                    ...m,
                    measurements: {
                        ...measure,
                        head: measure.head * 10,
                        neck: measure.neck * 10,
                        chest: measure.chest * 10,
                        waist: measure.waist * 10,
                        buttocks: measure.buttocks * 10,
                        leg_inner: measure.leg_inner * 10,
                        arm_outer: measure.arm_outer * 10,
                        height: measure.height * 10,
                        leg_circ: measure.leg_circ ? measure.leg_circ * 10 : undefined,
                        arm_circ: measure.arm_circ ? measure.arm_circ * 10 : undefined,
                        waist_female: measure.waist_female ? measure.waist_female * 10 : undefined
                    }
                }
            });
        }
        return measurements
    }
    getModels = async (): Promise<ObjectModel[]> => {
        const models = await this.getObjectModels();
        const measurements = await this.getMeasurements('mm');
        return models.filter(m=>m.taskID && measurements.some(meas=>meas.taskID===m.taskID) ).map((m) => toObjectModel(m, measurements.find(meas=>meas.taskID===m.taskID) as MeasureData));
    }
    getObjectModels = async (): Promise<ObjectModelData[]> => {
        return this.loadedModels ? this.objectModels : this.loadModels();
    }


    loadModels = async () => {
        this.objectModels = await getModels(this.ID,this.auth);
        this.loadedModels = true;
        return this.objectModels;
    }
    loadMeasurements = async (): Promise<MeasureData[]> => {
        this.measurements = await getMeasurements(this.ID,this.auth);
        const d = this.data || await getClient(this.ID,this.auth);
        this.measurements = this.measurements.map(m => normalizeToHeight(m, d.height));
        this.loadedMeasurements = true;
        return this.measurements;
    }
    loadClientData = async (): Promise<ClientData> => {
        this.data = await getClient(this.ID,this.auth);
        this.clientLoaded = true;
        return this.data;
    }
    load = async () => {
        await this.loadClientData();
        await this.loadMeasurements();
        await this.loadModels();
    }
    isLoaded = () => {
        return this.loadedModels && this.loadedMeasurements && this.clientLoaded;
    }
}



const getValue = (data: MeasureData,measurementType:string) => {
        const m = data.measurements;
        switch (measurementType) {
            case 'neck':
                return m.neck;
            case 'weight':
                return m.weight;
            case 'waist':
                return m.waist;
            case 'buttocks':
                return m.buttocks;
            case 'height':
                return m.height;
            case 'chest':
                return m.chest;
            default:
                return 0;
        }
    }
// 'neck'|'weight'|'waist'| 'height'| 'buttocks'

 const getMeasurementsData = async (clientId: string, measurementType: string, auth:AuthProvider): Promise<MeasurementPoint[]> => {
    // fetch data from server
    const client = await getClient(clientId,auth);
    if (!client) {
        return Promise.resolve([]);
    }
    const height = client.height;
    if (clientId.length > 1) {
        const measurements = await getMeasurements(clientId,auth);
        const res: MeasurementPoint[] = measurements.map(m => normalizeToHeight(m as MeasureData , height)).map(m => {
            return { id: m.ID || ' ', date: new Date(m.createdAt), value: getValue(m,measurementType) || 0 };
        });
    }
    const measurements = await getMeasurements(clientId,auth);
    if (!client) {
        return Promise.resolve([]);
    }
    measurementType = measurementType as keyof MeasureData['measurements'];
    
    const res: MeasurementPoint[] = measurements.map(m => normalizeToHeight(m, height)).map(m => {
        return { id: m.ID || ' ', date: new Date(m.createdAt), value: getValue(m, measurementType) || 0 };
    });
    return res;
} 
const normalizeToHeight = (measureData: MeasureData, heightInput: number): MeasureData => {
    const measure = {...measureData.measurements};
    //console.log('normalizeToHeight', heightInput, measure);
    let scale = (heightInput * 10) / measure.height;
    measure.head = measure.head/10 * scale ; 
    measure.neck = measure.neck/10 * scale;
    measure.chest = measure.chest/10 * scale;
    measure.waist = measure.waist/10 * scale;
    measure.buttocks = measure.buttocks/10 * scale;
    measure.leg_inner = measure.leg_inner/10 * scale;
    measure.arm_outer = measure.arm_outer/10 * scale;
    measure.height = measure.height/10 * scale;
    measure.leg_circ = measure.leg_circ ? measure.leg_circ/10 * scale : undefined;
    measure.arm_circ = measure.arm_circ ? measure.arm_circ/10 * scale : undefined;
    measure.waist_female = measure.waist_female ? measure.waist_female/10 * scale : undefined;
    return { ...measureData, measurements: measure };
}
const toObjectModel = (model: ObjectModelData, measurements: MeasureData): ObjectModel => {
    return {
        id: model.ID,
        createdAt: model.createdAt + '',
        obj: model.obj,
        visibleEffects: [0],
        showMeasurement: '',
        measurements: measurements.measurements
    }
}

export default getMeasurementsData;
