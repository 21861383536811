// trends component
import React, { useEffect } from "react";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
} from "@ionic/react";
import  {
  DashBoardComponentProps,
} from "../data/data";
import { LineGraph, LineGraphProps } from "./LineGraph";
import { useParams } from "react-router";
import { MeasureData } from "../../utils/interfaces";
import { showDifference } from "./GraphHeader";

export const Trends: React.FC<DashBoardComponentProps> = ({ clientHolder }) => {
  const id = useParams<{ id: string }>().id;
  const [meas, setMeas] = React.useState<LineGraphProps[]>([]);
  const setExpanded = (title: string) => {
    const newMeas = meas.map((m) => {
      if (m.title === title) {
        return { ...m, expanded: true };
      } else {
        return { ...m, expanded: false };
      }
    });
    setMeas(newMeas);
  };
  const fetchData = async () => {
    console.log("fetching data");
    const client = await clientHolder.getClient();
    const measurements = await clientHolder.getMeasurements();
    console.log(measurements);
    const measurementsKeys = [ "neck", "buttocks", 'chest', 'leg_circ', 'arm_circ',  "weight"];
    client.gender==='female'? measurementsKeys.unshift("waist_female"): measurementsKeys.unshift("waist");
    setMeas(
      measurementsKeys.map((m, i) => {
        return {
          title: m,
          expanded: false,
          values: measurements.map((d) => {
            const key = m as keyof MeasureData["measurements"];
            const value = d.measurements[key] || 0;
            return { id: i + "", date: new Date(d.createdAt), value: value };
          }).filter((d) => d.value !== 0),
        };
      })
    );
  };
  useEffect(() => {
   
    fetchData();
  }, []);

  return (
    <>
      {meas.map((measurement) => {
        return (
          <IonCard key={measurement.title} className="capitalize">
            <IonCardHeader>
              {showDifference(measurement.values, measurement.title)}
            </IonCardHeader>
            <IonCardContent onClick={() => setExpanded(measurement.title)}>
             {measurement.values.length>1 && <LineGraph
                expanded={measurement.expanded}
                title={measurement.title}
                values={measurement.values}
              ></LineGraph>}
            </IonCardContent>
          </IonCard>
        );
      })}
    </>
  );
};
