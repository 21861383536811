import { IonAvatar } from "@ionic/react";
import { useState } from "react";
interface ProfileImageProps {
    profileImage: string| undefined;
    slot: string|undefined;
    }
export const ProfileImage = (props:ProfileImageProps) => {
    const [image, setImage] = useState<string>();
    const formatImage = (image: string) => {
        if (image.startsWith("http")) {
            return image;
        }
        return `data:image/png;base64,${image}`;
    }
    
  return (<IonAvatar slot={props.slot}>

    <img
      alt="Face"
      src={
        formatImage(props.profileImage || "https://ionicframework.com/docs/img/demos/avatar.svg")
      }
    />
  </IonAvatar>);
};
