import {
    IonButton,
    IonCard,
    IonCardContent,
    IonContent,
    IonIcon,
    IonImg,
    IonPage,
    IonSpinner,
    IonText,
} from "@ionic/react";
import { useContext, useEffect, useMemo, useState } from "react";
import { Context } from "../MyContext";
import "../theme/PhotoPreview.css";
import "../theme/Utilities.css";
import { useParams } from "react-router";
import { ImageResource, OrientResource } from "../common/types";
import { orientationTransform } from '../common/orientation-transform';
import  CameraOverlay  from "../components/CameraOverlay";
import { chevronForwardOutline, refreshOutline } from "ionicons/icons";
import { addResource, AuthProvider, getResource } from "../pt/utils/http-service"; 
import { useAuth0 } from "@auth0/auth0-react";
const PhotoCapture: React.FC = () => {
    const auth = new AuthProvider(useAuth0().getAccessTokenSilently);
    const { id, gender, pose } = useParams<{ id: string, gender: string, pose: string }>()

    const [uploaded, setUploaded] = useState(false);

    const { frontImgDone, frontMaskDone, setFrontMaskDone, setFrontImgDone } = useContext(Context);
    const [image, setImage] = useState<ImageResource>();
    const [objectMask, setObjectMask] = useState<ImageResource>();
    const [orientation, setOrientation] = useState<OrientResource>();
    const which = "ready";
    const [error, setError] = useState("")
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setImage(undefined)
        setObjectMask(undefined)
        setFrontImgDone(false)
        setFrontMaskDone(false)
        setUploaded(false)
    }, [pose])


    const fetchImage = async () => {
        let i = 0;
        while (i < 5) {
            try {
                let response = await  getResource(`${pose}_img`,id, auth, true);

                setImage(await response.json());
                if (response.status === 200) return;
            } catch (error: any) {
                console.error(error);
            }
            await new Promise((resolve) => setTimeout(resolve, 500));
            i++;
        }
    };

    const fetchMask = async () => {

        try {
            let response = await getResource(`${pose}_mask`,id, auth, true);
            //let response = await fetch(`${PIPEDB_URL}/task/${id}/${pose}_mask`);
            setObjectMask(await response.json());
            if (response.status === 200) return;
        } catch (error: any) {
            console.error(error);
        }
    };

    const fetchOrientation = async () => {
        try {
            let response = await getResource(`${pose}_orient`,id, auth, true);
            //let response = await fetch(`${PIPEDB_URL}/task/${id}/${pose}_orient`);
            setOrientation(await response.json());
            if (response.status === 200) return;
        } catch (error: any) {
            console.error(error);
        }
    };
    const optimizeImages = async () => {

        console.log("Optimizing images", which);
        let response = await addResource(which, id, { createdBy: "app-alpha1" }, auth, true);
        console.log("Optimizing images", response.status, await response.json());
        await response.json();
    };

    const maskTransform = useMemo(() => {
        return orientationTransform(orientation?.orient);
    }, [orientation]);

    const uploadPhoto = async (photo: string, orientation: any) => {
        setUploaded(true);
        setIsLoading(true);
        setError("");
     
        let response = await addResource(`${pose}_angle`, id, { "beta": orientation.beta, "alpha": orientation.alpha, "gamma": orientation.gamma }, auth, true);
        
       
        response = await addResource(`${pose}_img`, id, { pixels: photo.split(",")[1], createdBy: "pro" }, auth, true);
        setUploaded(true);
        
        setIsLoading(false);
        console.log("Photo taken", response.status, await response.json());
        if (response.status >= 400) {
            console.log("Error uploading image, please retake photo", response.status);
            setError("Error uploading image, please retake photo")
            await fetchImage();
            return;
        }
        await Promise.all([fetchImage(), fetchMask(), fetchOrientation()]);
        

        setFrontMaskDone(true);
    }
    return (
        <IonPage className="z-50">
    
            {!uploaded ? <IonContent fullscreen>
                <CameraOverlay setUploaded={() => {}} id={id} pose={pose} gender={gender} uploadPhoto={uploadPhoto} />

            </IonContent> : <IonContent   fullscreen>
                {isLoading ? (
                    <div className="loadingIconText">
                        <div className="loadingIcon">
                            <IonSpinner name="lines-sharp"></IonSpinner>
                        </div>
                        <br></br>
                        <br></br>
                        <IonText className="loadingText">
                            <h1>Please wait...</h1>
                        </IonText>
                    </div>
                ) : (
                    <><div className="previewImageContainer">
                        <IonImg
                            src={`data:image/png;base64,${image?.pixels}`}
                            className="previewImage"
                            style={{ width: 420, height: 420 }} />
                        {!error ? <div className="previewMaskOverlay"><IonImg
                            src={`data:image/png;base64,${objectMask?.pixels}`}
                            className="previewMask"
                            style={{ transform: `${maskTransform}`, width: 420, height: 420 }} /></div> : <></>}
                    </div><IonCard color={error ? 'warning' : 'light'}>
                            <IonCardContent >
                                {error ? 'Make sure the person is positioned correctly, please retake photo' : 'Does the silhouette match person in the image, otherwise please retake photo.'}
                            </IonCardContent>
                        </IonCard><div className="buttons">
                            <IonButton size="large" fill="outline" color={"warning"} onClick={() => {
                                setUploaded(false)
                                setFrontMaskDone(false)
                                setFrontImgDone(false)

                            }}>
                                Retake photo <IonIcon src={refreshOutline}></IonIcon>
                            </IonButton>

                            {!error && (pose === 'front' ? <IonButton size="large" fill="outline" routerLink={`/task/${id}/${gender}/right_img`}>
                                Continue <IonIcon src={chevronForwardOutline}></IonIcon>
                            </IonButton> : <IonButton size="large" fill="outline" onClick={() => optimizeImages()} routerLink={`/task/${id}/result`}>
                                See results <IonIcon src={chevronForwardOutline}></IonIcon>
                            </IonButton>)
                            }
                        </div></>
                )}
            </IonContent>}
        </IonPage>
    );
};

export default PhotoCapture;
